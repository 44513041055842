import React, { useContext, useState, useEffect } from 'react';
import {
  Box,
  List,
  styled,
  Button,
  ListItem,
  Typography,
  Badge,
  alpha,
  Tooltip,
  tooltipClasses
} from '@mui/material';
import { NavLink as RouterLink } from 'react-router-dom';
import { SidebarContext } from 'src/contexts/SidebarContext';

import CatalogIcon from 'src/assets/ManagePageIcons/Catalogue.svg';
// import CalendarIcon from 'src/assets/svgIcons/CalendarIcon.svg'
import { useAuth } from 'src/Authentication/AuthContext';
import { useSelector } from 'react-redux';
import { getDecryptedData } from 'src/utility/LocalStorageManager';
// new icons
import dashboardIcon from '../../../../assets/customerSidebarIcons/dashboard.svg';
import analytics from '../../../../assets/customerSidebarIcons/analytics.svg';
import allCampaign from '../../../../assets/customerSidebarIcons/campaign.svg';
import chatIcon from '../../../../assets/customerSidebarIcons/chat.svg';
import customerIcon from '../../../../assets/customerSidebarIcons/customer.svg';
import formIcon from '../../../../assets/customerSidebarIcons/forms.svg';
import chatbotIcon from '../../../../assets/customerSidebarIcons/chatbot.svg';
import manageIcon from '../../../../assets/customerSidebarIcons/manage.svg';
import templateIcon from '../../../../assets/customerSidebarIcons/template.svg';
import teamsIcon from '../../../../assets/customerSidebarIcons/teams.svg';
import whatsappButtonIcon from '../../../../assets/customerSidebarIcons/whatsAppButton.svg';
import tagsIcon from '../../../../assets/customerSidebarIcons/tags.svg';

const NotificationsBadge = styled(Badge)(
  ({ theme }) => `
    .MuiBadge-badge {
        background-color: ${alpha(theme.palette.error.main, 0.1)} !important;
        color: ${theme.palette.error.main} !important;
        min-width: 16px; 
        height: 16px;
        padding: 0;

        &::after {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            border-radius: 50%;
            box-shadow: 0 0 0 1px ${alpha(theme.palette.error.main, 0.3)};
            content: "";
        }
    }
`
);

const MenuWrapper = styled(Box)(
  ({ theme }) => `
  background-color: ${theme.wappBizColor.white};
  .MuiList-root {
    padding:0px;
    margin:0px;
    display:flex;
    flex-direction:column;
    justify-content:center;
    align-items:center;
    
    & > .MuiList-root {
      padding: 0 ${theme.spacing(0)} ${theme.spacing(1)};
    }
  }

    .MuiListSubheader-root {
      text-transform: uppercase;
      font-weight: bold;
      font-size: ${theme.typography.pxToRem(12)};
      color: ${theme.colors.alpha.trueWhite[50]};
      padding: ${theme.spacing(0, 2.5)};
      line-height: 1.4;
    }
`
);

const SubMenuWrapper = styled(Box)(
  ({ theme }) => `
    .MuiList-root {
      .MuiListItem-root {
        width: auto !important;
        padding: 4px 4px;
        .MuiBadge-root {
          min-width: 0 !important;

          position: absolute;
          right: ${theme.spacing(3.2)};
          .MuiBadge-standard {
            background: ${theme.colors.primary.main};
            font-size: ${theme.typography.pxToRem(10)};
            font-weight: bold;
            text-transform: uppercase;
            color: ${theme.palette.primary.contrastText};
          }
        }

        .MuiButton-root {
          min-width: 0 !important;
          display: flex;
          color: ${theme.colors.alpha.black[100]};
          background-color: transparent;
          justify-content: center;
          transition: background-color ${theme.transitions.duration.short}ms;
          

          .MuiButton-startIcon,
          .MuiButton-endIcon {
            transition: ${theme.transitions.create(['color'])};
            .MuiSvgIcon-root {
              font-size: inherit;
              transition: none;
            }
          }

          .MuiButton-startIcon {
            color: ${theme.colors.alpha.trueWhite[30]};
            font-size: ${theme.typography.h1.fontSize};
            margin-right: ${theme.spacing(1)};
          }
          
          .MuiButton-endIcon {
            color: ${theme.colors.alpha.trueWhite[50]};
            margin-left: auto;
            opacity: .8;
            font-size: ${theme.typography.pxToRem(15)};
          }

          &:hover {
            // background-color: #BEFCC5;
            background-color: ${theme.wappBizColor.natural[100]};
             min-width:0px !important;
            .MuiButton-startIcon,
            .MuiButton-endIcon {
              // color: ${theme.colors.alpha.trueWhite[100]};
              color: ${theme.wappBizColor.natural[100]};
            }
          }

          &.active {
            // background-color: #27AB69;
            background-color: ${theme.wappBizColor.primary[50]};
            min-width:0px !important;
            // color: ${theme.colors.alpha.white[100]}; 
            color: ${theme.wappBizColor.primary[700]};           
            .MuiButton-startIcon,
            .MuiButton-endIcon {
              color: ${theme.colors.alpha.trueWhite[100]};
            }
          }

          &:active {  // This applies when the button is pressed
          background-color: ${theme.wappBizColor.primary[100]};           
           .MuiButton-startIcon,
           .MuiButton-endIcon {
           // If you want the icons to change color on click, specify here
           color: ${theme.wappBizColor.primary[700]};
           }
          }

          &.disabled {
            min-width:0px !important;
            pointer-events: none;
            opacity: 0.5;
          }
        }

        &.Mui-children {
          flex-direction: column;

          .MuiBadge-root {
            position: absolute;
            right: ${theme.spacing(7)};
          }
        }

        .MuiCollapse-root {
          width: 100%;

          .MuiList-root {
            padding: ${theme.spacing(1, 0)};
          }

          .MuiListItem-root {
            padding: 1px 0;

            .MuiButton-root {

              .MuiBadge-root {
                right: ${theme.spacing(3.2)};
              }

              &:before {
                content: ' ';
                background: ${theme.colors.alpha.trueWhite[100]};
                opacity: 0;
                transition: ${theme.transitions.create([
                  'transform',
                  'opacity'
                ])};
               
                transform: scale(0);
                transform-origin: center;
                border-radius: 20px;
                margin-right: ${theme.spacing(1.8)};
              }

              &.active,
              &:hover {
                &:before {
                  transform: scale(1);
                  opacity: 1;
                }
              }
            }
          }
        }
      }
    }
`
);

const StyledButton = styled(Button)`
  && {
    border-radius: 8px;
    // margin-bottom: 3px;
    background-color: #ffffff; /* Add your desired background color */
    // transition: background-color 0.3s ease; /* Add transition effect for hover */
    padding: 8px; /* Adjust padding as needed */

    & img {
      width: 22px;
      height: 22px;
      filter: brightness(0) invert(0);
    }
    &:hover img,
    &.active img {
      filter: invert(49%) sepia(79%) saturate(406%) hue-rotate(98deg)
        brightness(95%) contrast(88%);
    }
  }
`;

const IconHeading = styled(Typography)`
  text-align: center;
  display: inline-block;
  font-size: 11px;
  font-weight: 600;
  margin-top: 5px;
`;

// Custom styled Tooltip
// const CustomTooltip = styled(({ className, ...props }) => (
//   <Tooltip {...props} classes={{ popper: className }} arrow />
// ))(({ theme }) => ({
//   [`& .${tooltipClasses.tooltip}`]: {
//     backgroundColor: theme.wappBizColor.black,
//     color: theme.wappBizColor.white,
//     fontWeight: 400,
//     alignItems:'center',
//     fontSize: theme.typography.pxToRem(12),
//     // border: `1px solid ${theme.palette.primary.main}`,
//     padding: theme.spacing(1),
//     borderRadius: '4px',
//     height: '40px'
//   },
//   [`& .${tooltipClasses.arrow}`]: {
//     color: theme.wappBizColor.black
//   }
// }));

const CustomTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} arrow />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.wappBizColor.black,
    color: theme.wappBizColor.white,
    fontWeight: 400,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: theme.typography.pxToRem(12),
    padding: theme.spacing(1),
    borderRadius: '4px'
    // height: '40px'
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.wappBizColor.black,
    '&::before': {
      // This is where the actual arrow is styled
      backgroundColor: theme.wappBizColor.black,
      // boxShadow: '0 2px 2px 0 rgba(0,0,0,0.2)',  // Optional: adds a soft shadow to mimic curvature
      borderRadius: '2px' // Soften edges a bit; adjust as needed
    }
  }
}));

function SidebarMenu() {
  const { closeSidebar } = useContext(SidebarContext);
  const { unreadChatCounts, businessProfileData, isRegister } = useSelector(
    (state) => state.wapp
  );

  const { isAuthenticated } = useAuth();
  // console.log(isRegister);

  // For allowing and hiding Premium feature
  const [isAllow, SetIsAllow] = useState(false);

  // Fetching Data from Context
  useEffect(() => {
    if (isAuthenticated) {
      // const userInfo = JSON.parse(sessionStorage.getItem('userInfo'));
      const userInfo = getDecryptedData('userInfo');
      // console.log('User Info:', userInfo);
      if (userInfo.planName === 'Premium') {
        SetIsAllow(true);
      }
    }
  }, [isAuthenticated]);

  const menuItems = [
    { to: '/', icon: dashboardIcon, label: 'Dashboard' },
    { to: '/analytics', icon: analytics, label: 'Analytics' },
    {
      to: '/chats',
      icon: chatIcon,
      label: 'Chats',
      badgeContent: unreadChatCounts
    },
    { to: '/AllCampaigns', icon: allCampaign, label: 'Campaigns' },
    // { to: '/broadcasts', icon: broadcastIcon, label: 'Broadcast' },
    { to: '/customers', icon: customerIcon, label: 'Customers' },
    { to: '/templates', icon: templateIcon, label: 'Templates' },
    { to: '/chatbot', icon: chatbotIcon, label: 'Chatbot' },
    // { to: '/calendar', icon: CalendarIcon, label: 'calendar' }
    { to: '/teams', icon: teamsIcon, label: 'Team' },
    {
      to: '/whatsappButton',
      icon: whatsappButtonIcon,
      label: 'Whatsapp Button'
    },
    { to: '/tags', icon: tagsIcon, label: 'Tags' },
    { to: '/manage', icon: manageIcon, label: 'Manage' }
  ];

  if (isAllow && businessProfileData?.message_limit !== '250') {
    menuItems.push({
      to: '/formResponses',
      icon: formIcon,
      label: 'Form Responses'
    });
    // menuItems.push({ to: '/catalog', icon: CatalogIcon, label : 'Catalog'})
  }

  return (
    <MenuWrapper>
      <List component="div">
        <SubMenuWrapper>
          <List component="div">
            {menuItems.map(({ to, icon, label, badgeContent }) => (
              <ListItem component="div" key={label}>
                <CustomTooltip title={label} placement="right">
                  <StyledButton
                    disableRipple
                    component={RouterLink}
                    onClick={closeSidebar}
                    to={to}
                    activeclassname="active"
                    className={
                      businessProfileData?.isRegistered === false ||
                      (isRegister === 0 && to !== '/')
                        ? 'disabled'
                        : ''
                    }
                  >
                    <Box
                      display="flex"
                      flexDirection="column"
                      alignItems="center"
                    >
                      <img src={icon} alt={`${label} Icon`} />
                      {/* <IconHeading>{label}</IconHeading> */}
                      {badgeContent > 0 && (
                        <NotificationsBadge
                          badgeContent={badgeContent}
                          anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'right'
                          }}
                        />
                      )}
                    </Box>
                  </StyledButton>
                </CustomTooltip>
              </ListItem>
            ))}
          </List>
        </SubMenuWrapper>
      </List>
    </MenuWrapper>
  );
}

export default SidebarMenu;
